import { device } from 'src/styles/breakpoints'
import { gray, white } from 'src/styles/colors'
import styled from 'styled-components'

export const Section = styled.div`
  h1, h2 {
    font-family: "Sora", Helvetica, sans-serif;
  }

  .bread-crumb {
    span {
      color: ${gray['800']} !important;

      a {
        color: ${gray['800']};
        font-weight: 900;
      }
    }
  }

  .gatsby-image-wrapper {
    max-width: 456px;
    margin: 0 auto;
    margin-right: 0;
  }
`

export const HighlightWrapper = styled.div`
  > section {
    align-items: center;
    min-height: 100vh;

    &::before {
      background-image: linear-gradient(0deg, rgba(198, 71, 0, 0.8), rgba(198, 71, 0, 0.8));
    }

    @media ${device.tablet} {
      min-height: 768px;
    }

    >.container {
      padding-top: 60px;

      @media ${device.desktopLG} {
        padding-top: 0;
      }
    }
  }
`
export const FAQSection = styled.section`
  .search-input {
    background: ${white};
  }
`

export const Arrow = styled.div`
  bottom: 20px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
`
